export class EBAssets {
  constructor(
    private version: string,
    private appName: string
  ) {}

  get(address: string): string {
    if (!address || /^https?:\/\//i.test(address)) {
      return address;
    }

    const lastIndexOfDot = address.lastIndexOf('.');
    const fileExt = address.substring(lastIndexOfDot + 1);
    const fileWithoutExt = address.substring(0, lastIndexOfDot);

    return (
      `/${this.appName}/generated/assets/${fileWithoutExt}${this.version}` +
      (fileExt ? `.${fileExt}` : '')
    );
  }
}
